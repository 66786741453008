import request from '@/utils/request'

// 促销时间：2024.11.9
// 促销内容：会议当天金+和钻卡加入人员，免费获得老款魔力仪1台。升级到金+人员加100美元获得老款魔力仪1台。升级到钻卡人员免费获得老款魔力仪1台。
// 操作方式：升级到金+弹对话框，询问是否加赠
export function RUSAugustPromotion1109() {
    return request({
        url: '/common/RUSAugustPromotion1109',
        method: 'get'
    })
}

// 黑五促销
// 促销时间：2024.11.10-2024.11.17
export function RUSAugustPromotion1110() {
    return request({
        url: '/common/RUSAugustPromotion1110',
        method: 'get'
    })
}
