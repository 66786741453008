import request from '@/utils/request'

// 获取6位时间
export function getMemberNoDateFormat() {
    return request({
        url: '/register/getMemberNoDateFormat',
        method: 'get'
    })
}

//注册经销商第一步  代办处/经销商
export function agentRegisterFirst(data) {
    return request({
        url: '/register/agentRegisterFirst',
        method: 'post',
        data
    })
}

//注册经销商第二步  代办处/经销商
export function agentRegisterSecond(data) {
    return request({
        url: '/register/agentRegisterSecond',
        method: 'post',
        data
    })
}

//新建首购单
export function memberRegisterSecond(data) {
    return request({
        url: '/register/memberRegisterSecond',
        method: 'post',
        data
    })
}

export function merRestFist(data) {
    return request({
        url: '/register/memberRegisterFirst',
        method: 'post',
        data
    })
}

export function memberNoIsExit(query) {
    return request({
        url: 'common/memberNoIsExit',
        method: 'get',
        params: query
    })
}

//获取自己新编号
export function registerNewGetNewNo(query) {
    return request({
        url: '/registerNew/getNewNo',
        method: 'get',
        params: query
    })
}

//获取自己的所有点编号
export function registerNewGetAllUserNo(query) {
    return request({
        url: '/registerNew/getAllUserNo',
        method: 'get',
        params: query
    })
}

//注册经销商第一步
export function NewAgentRegisterFirst(data) {
    return request({
        url: '/registerNew/agentRegisterFirst',
        method: 'post',
        data
    })
}

//注册经销商第二步
export function NewAgentRegisterSecond(data) {
    return request({
        url: '/registerNew/agentRegisterSecond',
        method: 'post',
        data
    })
}
