<!-- 文件中文名: 为自己注册新点位 -->
<template>
  <div class="MineSgOrder app-container" style="text-align: left">
    <div v-show="step===1">
      <el-form
          ref="registerRef"
          :model="registerInfo"
          :rules="rules"
          label-width="auto"
          size="mini"
      >
        <!-- 基本信息 -->
        <h3 style="color: #b3640d">{{ $t('Common.basicInfomation') }}</h3>
        <!-- 经销商编号 -->
        <el-form-item :label="$t('miMember.memberNo')">
          {{ memberNo }}
        </el-form-item>
        <!-- 状态 -->
        <el-form-item :label="$t('sys.status_es_ES')" prop="totalFc">
          <el-select v-model="registerInfo.totalFc" clearable :placeholder="$t('mi.input.select')"
                     @change="changeStatus($event)">
            <el-option value="100"/>
            <el-option value="300"/>
            <el-option value="500"/>
            <el-option value="1500"/>
          </el-select>
        </el-form-item>
        <!-- 推荐人编号 -->
        <el-form-item :label="$t('mi.sellerNo') + ':'" prop="recommendNo">
          <el-select v-model="registerInfo.recommendNo" :placeholder="$t('mi.input.select')">
            <el-option v-for="item in recommendNoList" :key="item" :label="item" :value="item"/>
          </el-select>
        </el-form-item>
        <!-- 安置人编号 -->
        <el-form-item :label="$t('mi.servicerNo_es_ES') + ':'" prop="linkNo">
          <el-input
              @input="value=>registerInfo.linkNo=value.toUpperCase()"
              v-model.trim="registerInfo.linkNo"
              clearable
              style="width: 80%;max-width: 193px"
              @blur="tmpLinkNoBlur($event.target.value)"
          />
          <span :class="{ account_name_css: tmpLinkNo_span_show }" class="red-span">
                        {{ tmpLinkNo_span }}
                    </span>
        </el-form-item>
        <!--        电子邮箱-->
        <el-form-item :label="$t('sys.email')" prop="email">
          <el-input v-model="registerInfo.email" :placeholder="$t('sys.email')" style="width: 80%;max-width: 193px"/>
          <p style="color: red" v-if="!registerInfo.email">{{ this.$t('Please.fill.in.the.email') }}</p>
        </el-form-item>
        <!-- 位置 -->
        <el-form-item :label="$t('sys.Location')" prop="lr">
          <el-radio-group v-model="registerInfo.lr">
            <el-radio
                :disabled="disabled1"
                label="0"
                @change="changRadio($event)"
            >{{ $t('common.left') }}
            </el-radio>
            <el-radio
                :disabled="disabled2"
                label="1"
                @change="changRadio($event)"
            >{{ $t('common.right') }}
            </el-radio
            >
          </el-radio-group>
        </el-form-item>
        <!-- 收货信息 -->
        <h3 style="color: #b3640d">{{ $t('register.us.legend.shipping') }}</h3>
        <!-- 收获方式 -->
        <el-form-item :label="$t('PoOrder.recType')" required>
          <el-radio v-model="registerInfo.recType" label="1" @change="setAgentAddr">
            <!--                代办处提货-->
            {{ this.$t('ru.please.goto.agent.toGet') }}
          </el-radio>
          <br>
          <el-radio v-if="companyCode !== 'LTV'" v-model="registerInfo.recType" label="2" @change="setCompanyAddr">
            <!--                分公司提货-->
            {{ this.$t('pick.up.from.branch.offices') }}
          </el-radio>
          <br v-if="companyCode !== 'LTV'">
          <!--                发到经销商-->
          <el-radio v-model="registerInfo.recType" label="3" @change="setMemberAddr">
            {{ this.$t('GoodsToMember') }}
          </el-radio>
          <br>
        </el-form-item>
        <div v-show="registerInfo.recType==='1'">
          <!--          代办处提货-->
          <el-form-item :label="$t('ru.please.goto.agent.toGet')" required size="mini" style="width: 100%">
            <el-radio-group v-model="registerInfo.recNo" size="mini" style="width: 100%;"
                            value-key="agentNo">
              <div
                  v-for="(item,index) in AgentGetGoodsList"
                  :key="item.agentNo"
                  style="margin: 5px 0;width: 100%;border-top: 1px solid #ddd"
                  :class="{ 'under-line': index===AgentGetGoodsList.length-1 }"
                  @click="agentRadioChange(item)"
              >
                <el-radio :label="item.agentNo" class="AgentRadio"
                          style="height: auto;overflow: hidden;">
                  <div style="display: inline-block">
                    <p style="display: block;white-space: pre-wrap;word-break:break-word;overflow: hidden;padding-right: 80px">
                      {{ item.companyCode }}&nbsp;&nbsp;
                      {{ item.name }}&nbsp;&nbsp;&nbsp;({{ item.agentNo }})&nbsp;&nbsp;&nbsp;
                      {{ item.mobile }}
                    </p>
                    <p style="display: block;white-space: pre-wrap;word-break:break-word;overflow: hidden;padding-right: 80px">
                      {{ item.storeAddr }}</p>
                  </div>
                </el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
        </div>
        <div v-show="registerInfo.recType==='2'">
          <!--          从分公司提货-->
          <el-form-item :label="$t('pick.up.from.branch.office')" required size="mini" style="width: 100%">
            <el-radio-group v-model="registerInfo.outCompanycode" size="mini" style="width: 100%"
                            value-key="agentNo">
              <div
                  v-for="(item,index) in CompanyGetGoodsList"
                  :key="item.City"
                  :class="{ 'under-line': index===CompanyGetGoodsList.length-1 }"
                  style="margin: 5px 0;width: 100%;border-top: 1px solid #ddd"
                  @click="outCompanyCodeRadioChange(item)"
              >
                <el-radio :label="item.companyCode" class="AgentRadio"
                          style="height: auto;overflow: hidden;">
                  <div style="display: inline-block">
                    <p>{{ $t(item.companyCode) }}-{{ $t(item.Name) }}</p>
                    <p>{{ $t(item.Addr) }}</p>
                  </div>
                </el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
        </div>
        <div v-show="registerInfo.recType==='3'">
          <!--          地址-->
          <el-form-item :label="$t('mi.address.id')" required>
            <el-select v-model="countryCode" value-key="companycode" filterable @change="countryChange"
                       popper-class="selectAddr">
              <el-option
                  v-for="item in countryList"
                  :key="item.id"
                  :label="item.country"
                  :value="item"/>
            </el-select>
            <el-select v-model="cityCode" filterable @change="cityChange" popper-class="selectAddr">
              <el-option
                  v-for="item in cityList"
                  :key="item.id"
                  :label="item.province"
                  :value="item.id"/>
            </el-select>
          </el-form-item>
          <!--运费-->
          <el-form-item v-show="getSendFree" :label="$t('shipping.cost')">
            {{ getSendFree }}
          </el-form-item>
          <!--          详细地址-->
          <el-form-item :label="$t('miAgent.storeAddr')" required size="mini" style="width: 100%">
            <el-input v-model="registerInfo.recAddr" :readonly="registerInfo.recType==='1'"/>
          </el-form-item>
          <!--          收货人姓名-->
          <el-form-item :label="$t('busi.JPorder.receiverName')+(':')" required>
            <el-input v-model="registerInfo.recName" :readonly="registerInfo.recType==='1'"/>
          </el-form-item>
          <!--          收货电话-->
          <el-form-item :label="$t('ReceiveGoodsPhoneNumber')+(':')" required>
            <el-input v-model="registerInfo.recPhone" :readonly="registerInfo.recType==='1'"/>
          </el-form-item>
        </div>
        <!-- 密码设置 -->
        <h3 style="color: #b3640d">{{ $t('miAgent.title.LoginInfo') }}</h3>
        <!-- 设置密码 -->
        <el-form-item :label="$t('sysUser.password') + ':'" prop="password">
          <el-input
              v-model.trim="registerInfo.password"
              clearable
              show-password
              style="width: 80%"
          />
        </el-form-item>
        <!-- 重复密码 -->
        <el-form-item
            :label="$t('sysUser.repeatPassword') + ':'"
            prop="tmpRePassword"
        >
          <el-input
              v-model.trim="registerInfo.tmpRePassword"
              clearable
              show-password
              style="width: 80%"
          />
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin-bottom: 10px">
        <!--      下一步-->
        <el-button
            v-loading="doLoading"
            icon="el-icon-right"
            size="mini"
            type="success"
            @click="showStep2"
        >{{ $t('button.next') }}
        </el-button>
      </div>
    </div>
    <div v-show="step===2">
      <!-- 商品列表 -->
      <h3 style="color: #b3640d">{{ $t('mall.ProductsList') }}</h3>
      <el-table :data="goodsList" v-loading="doLoading" stripe border height="600">
        <el-table-column :label="$t('image')" align="center">
          <template slot-scope="{ row }">
            <el-image
                v-show="row.imgsrc"
                :src="row.imgsrc"
                fit="contain"
                style="width: 100%; height: 100%; border: 0.5px #eeeeee solid"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column :label="$t('sr.productName')" align="center" prop="goodsName">
          <template slot-scope="{ row }">
            {{ $t(row.goodsName) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('Product.Code')" align="center" prop="goodsCode"/>
        <el-table-column :label="$t('biBarCode.quantity')" align="center" width="140">
          <template slot-scope="{ row }">
            <el-input-number
                v-model.number="row.quantity"
                :min="0"
                size="small"
            />
          </template>
        </el-table-column>
        <!--价格-->
        <el-table-column :label="$t('pd.price')" align="right">
          <template slot-scope="{ row }">
            <p>{{ row.standardPriceB >= 999999 ? '--' : row.standardPriceB }}{{ $symbol() }}</p>
          </template>
        </el-table-column>
        <!--价格-->
        <el-table-column :label="$t('pd.price')" align="right">
          <template slot-scope="{ row }">
            <!--                        <p>{{ row.standardPrice >= 999999 ? '&#45;&#45;' : row.standardPrice }}W$</p>-->
            <p>{{ row.standardPrice >= 999999 ? '--' : row.standardPrice }}</p>
          </template>
        </el-table-column>
        <el-table-column label="PV" align="right">
          <template slot-scope="{ row }">
            <p>{{ row.standardPv }}</p>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex;justify-content: space-between">
        <p>{{ $t('sys.status') }}: {{ registerInfo.totalFc }}</p>
        <p>{{ $t('TotalPrice') }}: {{ getTotalSum(1) }}{{ $symbol() }} &nbsp;
          ({{ getTotalSum(2) }}) &nbsp;
          {{ $t('company.me.totalPV') }}: {{ getTotalSum(3) }}
        </p>
      </div>
      <div>
        <!--          促销组件-->
        <publicpage  :orderType="17" :selectlevel="selectlevel"  @confirm="showStep3"></publicpage>
      </div>
      <div style="text-align: center">
        <!--      上一步-->
        <el-button
            v-loading="doLoading"
            icon="el-icon-back"
            size="mini"
            type="primary"
            @click="showStep1"
        >{{ $t('button.previous') }}
        </el-button>
        <!--      下一步-->
        <el-button
            v-loading="doLoading"
            icon="el-icon-right"
            size="mini"
            type="success"
            @click="aWhetherexecute"
        >{{ $t('button.next') }}
        </el-button>
      </div>
    </div>
    <div v-show="step===3">
      <!-- 基本信息 -->
      <h3 style="color: #b3640d">{{ $t('Common.basicInfomation') }}</h3>
      <!-- 经销商编号 -->
      <el-form>
        <el-form-item :label="$t('miMember.memberNo')" size="mini">
          {{ memberNo }}
        </el-form-item>
        <!-- 状态 -->
        <el-form-item :label="$t('sys.status')" size="mini">
          {{ registerInfo.totalFc }}
        </el-form-item>
        <!-- 推荐人编号 -->
        <el-form-item :label="$t('mi.sellerNo')" size="mini">
          {{ registerInfo.recommendNo }}
        </el-form-item>
        <!-- 安置人编号 -->
        <el-form-item :label="$t('mi.servicerNo')" size="mini">
          {{ registerInfo.linkNo }}
        </el-form-item>
        <!-- 电子邮件 -->
        <el-form-item :label="$t('sys.email')">
          {{ registerInfo.email }}
        </el-form-item>
        <!-- 位置 -->
        <el-form-item :label="$t('sys.Location')" size="mini">
          <span v-if="registerInfo.lr==0">{{ $t('common.left') }}</span>
          <span v-else>{{ $t('common.right') }}</span>
        </el-form-item>
        <!-- 收货信息 -->
        <h3 style="color: #b3640d">{{ $t('register.us.legend.shipping') }}</h3>
        <!-- 收获方式 -->
        <el-form-item :label="$t('PoOrder.recType')" size="mini">
          <span v-show="registerInfo.recType==1">{{ $t('ru.please.goto.agent.toGet') }}</span>
          <span v-show="registerInfo.recType==2">{{ $t('pick.up.from.branch.offices') }}</span>
          <span v-show="registerInfo.recType==3">{{ $t('GoodsToMember') }}</span>
        </el-form-item>
        <!--          地址-->
        <el-form-item v-show="registerInfo.regionCode" :label="$t('mi.address.id')" size="mini">
          {{ registerInfo.regionString }}
        </el-form-item>
        <!--          详细地址-->
        <el-form-item v-show="registerInfo.recAddr" :label="$t('miAgent.storeAddr')" size="mini">
          {{ registerInfo.recAddr }}
        </el-form-item>
        <!--          收货人姓名-->
        <el-form-item v-show="registerInfo.recName" :label="$t('busi.JPorder.receiverName')" size="mini">
          {{ registerInfo.recName }}
        </el-form-item>
        <!--          收货电话-->
        <el-form-item v-show="registerInfo.recPhone" :label="$t('ReceiveGoodsPhoneNumber')" size="mini">
          {{ registerInfo.recPhone }}
        </el-form-item>
        <!-- 商品列表 -->
        <h3 style="color: #b3640d">{{ $t('mall.ProductsList') }}</h3>
        <el-table :data="resGoodsList" v-loading="doLoading" stripe border>
          <el-table-column :label="$t('image')" align="center">
            <template slot-scope="{ row }">
              <el-image
                  v-show="row.imgsrc"
                  :src="row.imgsrc"
                  fit="contain"
                  style="width: 100%; height: 100%; border: 0.5px #eeeeee solid"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column :label="$t('sr.productName')" align="center" prop="goodsName">
            <template slot-scope="{ row }">
              {{ $t(row.goodsName) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('Product.Code')" align="center" prop="goodsCode"/>
          <!--价格-->
          <el-table-column :label="$t('pd.price')" align="right">
            <template slot-scope="{ row }">
              <!--                            <p>{{ row.standardPriceB }}{{ $symbol() }} ({{ row.standardPrice }}W$)</p>-->
              <p>{{ row.standardPriceB }}{{ $symbol() }} ({{ row.standardPrice }})</p>
            </template>
          </el-table-column>
          <el-table-column label="PV" align="right" prop="standardPv"/>
          <el-table-column :label="$t('biBarCode.quantity')" align="center" prop="quantity"/>
        </el-table>
        <div>
          <p>{{ $t('TotalPrice') }}: {{ getTotalSum2(1) }}{{ $symbol() }} &nbsp;
            ({{ getTotalSum2(2) }}) &nbsp;
            {{ $t('company.me.totalPV') }}: {{ getTotalSum2(3) }} &nbsp;
            {{ $t('shipping.cost') }}: {{ getSendFree }}{{ $symbol() }} &nbsp;
            {{ $t('bdSendEfuboBonusDetail.amtCount') }}: {{ getTotalSum2(1) + getSendFree }}{{ $symbol() }}
          </p>
        </div>
        <table style="border-collapse: collapse;border: 1px solid #EBEEF5;margin: 0 auto 20px">
          <thead>
          <tr>
            <th style="border: 1px solid #EBEEF5;text-align: center"></th>
            <!-- 本位币钱包 -->
            <th style="border: 1px solid #EBEEF5;text-align: center">{{ $t('Base.currency.wallet') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td style="border: 1px solid #EBEEF5;text-align: center;min-width: 80px">
              <p>{{ $t('pmEfuboCard.balance') }}</p>
            </td>
            <td style="border: 1px solid #EBEEF5;text-align: center"><span style="color: red">MXN </span>{{ this.B$ }}</td>
          </tr>
          <tr>
            <td style="border: 1px solid #EBEEF5;text-align: center;min-width: 80px">
              <p class="hong-xing">{{ $t('Common.pay') }}</p>
            </td>
            <td style="border: 1px solid #EBEEF5;text-align: center">
              <el-input class="el-input-center" readonly
                        v-model.number="registerInfo.totalAmount" >
                <template slot="prepend">MXN</template>
              </el-input>
            </td>
          </tr>
          </tbody>
        </table>
      </el-form>
      <div style="text-align: center">
        <!--      上一步-->
        <el-button
            plain
            :loading="doLoading"
            icon="el-icon-back"
            size="mini"
            type="primary"
            @click="Back()"
        >{{ $t('button.previous') }}
        </el-button>
        <!--      立即支付-->
        <el-button
            plain
            :loading="doLoading"
            icon="el-icon-right"
            size="mini"
            type="success"
            @click="submitData(0)"
        >{{ $t('Pay.Immediately') }}
        </el-button>
      </div>
      <div style="margin-top: 20px">
        <span> </span>
      </div>
<!--      <div style="text-align: right;color: red">-->
<!--        <p style="margin: 0">{{ $t('Payment.exchange_rate.convert') }}</p>-->
<!--        <p style="margin: 0">{{ $t('ltv.pay.online.message1') }}</p>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import {
  memberNoIsExit,
  NewAgentRegisterFirst,
  NewAgentRegisterSecond,
  registerNewGetAllUserNo,
  registerNewGetNewNo
} from "@/api/register/register";
import {miMemberRightNum} from "@/api/common/common";
import {
  commonGetMiAgents,
  getPersonalData2,
  commonGetRecAddrs,
  getSGGoodsPage
} from "@/api/rus/api";
import {debounce as _debounce} from "lodash"
import {mapGetters} from 'vuex'
import publicpage from "@/components/Promotions/publicpage/index.vue";
import {RUSAugustPromotion1109, RUSAugustPromotion1110} from "@/api/promotion/time";

export default {
  name: 'MineSgOrder',
  components:{publicpage},
  directives: {},
  data() {
    return {
      get1110time:false,
      get1109time:false,
      popoverVisible: false,
      currencyInfo: {
        money: 0
      },
      selectlevel:'',
      countryCode: {companycode: ''},
      cityCode: null,
      countryList: [],
      cityList: [],
      FVT003EQTY: 0,
      tableData: [],
      B$: 0,
      resSaveInfo: {},
      step: 1,
      goodsList: [],
      resGoodsList: [],
      recommendNoList: [],
      memberNo: '',
      prohibitregistration: '',
      canregister: false,
      CompanyGetGoodsList: [], // 分公司提货列表
      AgentGetGoodsList: [], // 提货代办处列表
      listQuery: {
        page: 1,
        limit: 20
      },
      registerInfo: {
        "totalAmount": 0,
        "totalFb": 0,
        "outCompanycode": "",
        "recNo": "",
        "recAddr": "",
        "recPhone": "",
        "recName": "",
        "name": "",
        "petName": "",
        "totalFc": "",
        "recommendNo": "",
        "linkNo": "",
        "lr": "",
        "recType": '3',
        "regionCode": "",
        "password": "",
        "tmpRePassword": ""
      },
      AgentInfoObj: null,
      CompanyInfoObj: null,
      doLoading: false,
      recommendName_span_show: false,
      tmpLinkNo_span_show: false,
      recommendName_span: '',
      tmpLinkNo_span: '',
      tmpLinkNo_num_span: '',
      memberNo_span: '',
      disabled1: true,
      disabled2: true,
      rules: {
        totalFc: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        lr: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        recommendNo: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        linkNo: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        email: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        country: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        storeAddr: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        accountBank: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        accountName: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        accountCode: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        password: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        tmpRePassword: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
      },
      total: 0,
      initDict: {
        outCompanyList: 'out.company.list',
        sendfreeList: 'sendfree.weu'
      },
    }
  },
  computed: {
    ...mapGetters([
      'companyCode',
      'userCode',
      'username',
      'ckCode',
      'goodsKindCode',
      'goodsKindName',
      "shoppingList"
    ]),
    outCompanyList() {
      return this.$ll(this.initDict.outCompanyList)
    },
    getSendFree() {
      if (!this.countryCode.companycode) {
        return 0
      }
      return Number(this.$lt(this.initDict.sendfreeList, this.countryCode.companycode)) || 250
    }
  },
  async created() {
    this.doLoading = true
    this.countryList = await this.getAddrList('')
    this.getTime()
    await commonGetMiAgents()
        .then(res => {
          this.AgentGetGoodsList = res.data
        })
        .catch(err => {
          console.error(err)
        })
    await getSGGoodsPage()
        .then(res => {
          res.data.map(item => {
            item.quantity = 0
          })
          this.goodsList = res.data
          for (let a = 0; a < this.goodsList.length; a++) {
            if (this.goodsList[a].imgsrc.indexOf(';') !== -1) {
              this.goodsList[a].imgsrc = this.goodsList[a].imgsrc.split(';')[0]
            }
          }
        })
        .catch(err => {
          console.error(err)
        })
    await registerNewGetNewNo()
        .then(res => {
          this.memberNo = res.data
        })
        .catch(err => {
          console.error(err)
        })
    await registerNewGetAllUserNo()
        .then(res => {
          this.recommendNoList = res.data
        })
        .catch(err => {
          console.error(err)
        })
    await this.getBalance()
  },
  mounted() {
    // if (this.companyCode === 'LTV') {
    //   this.CompanyGetGoodsList = [{Name: 'LTV_Company_Name', Addr: 'LTV_Company_Addr', companyCode: 'LTV'}] // 立陶宛
    // }
    this.CompanyGetGoodsList = [
      {Country: 'MEX_Country', City: 'MEX_City', Addr: 'MEX_Company_Addr', Tel: 'MEX_Company_Tel', companyCode: 'MEX'} // 墨西哥
    ]
  },
  methods: {
    async countryChange(val) {
      this.cityList = await this.getAddrList(val.country)
      this.registerInfo.regionCode = null
      this.cityCode = null
    },
    getTime(){
      RUSAugustPromotion1110().then(res=>{
        this.get1110time = res.data
      })
      RUSAugustPromotion1109().then(res=>{
        this.get1109time = res.data
      })
    },
    cityChange(val) {
      this.registerInfo.regionCode = val
      let cityItem = this.cityList.filter(item => {
        return item.id === val
      })[0]
      this.registerInfo.regionString = cityItem.country + ' ' + cityItem.province
    },
    getAddrList(countryCode) {
      return new Promise((resolve, reject) => {
        commonGetRecAddrs({country: countryCode}).then(res => {
          resolve(res.data)
        }).catch(err => {
          console.warn(err)
          reject(err)
        })
      })
    },
    setAmount: _debounce(function (e, type) {
      console.log(e,type,'123')
      if (isNaN(Number(e))) {
        this.registerInfo.totalAmount = 0
        this.$message.error(this.$t('register.us.select.number').toString()) // 请输入数字
        return false
      }
        // if (type === 1) { //电子钱包修改执行此代码块
        //   this.registerInfo.totalAmount = this.getTotalSum2(1)
        // }
    }, 500),
    getTotalSum(type) {
      let standardPriceB = 0
      let standardPrice = 0
      let standardPv = 0
      this.goodsList.map(item => {
        standardPriceB += item.standardPriceB * item.quantity
        standardPrice += item.standardPrice * item.quantity
        standardPv += item.standardPv * item.quantity
      })
      if (type === 1) {
        return standardPriceB
      } else if (type === 2) {
        return standardPrice
      } else if (type === 3) {
        return standardPv
      } else {
        return ''
      }

    },
    getTotalSum2(type) {
      let standardPriceB = 0
      let standardPrice = 0
      let standardPv = 0
      this.resGoodsList.map(item => {
        standardPriceB += item.standardPriceB * item.quantity
        standardPrice += item.standardPrice * item.quantity
        standardPv += item.standardPv * item.quantity
      })
      if (type === 1) {
        this.registerInfo.totalAmount = standardPriceB
        return standardPriceB
      } else if (type === 2) {
        return standardPrice
      } else if (type === 3) {
        return standardPv
      } else {
        return ''
      }
    },
    showStep1() {
      this.step = 1
      this.selectlevel = ''
    },
    checkTotalFcAndSum(totalFc, totalSum) {
      const totalFcThresholds = {
        '100': 100,
        '300': 300,
        '500': 500,
        '1500': 1500
      }
      const threshold = totalFcThresholds[totalFc]
      if (threshold && totalSum < threshold) {
        console.log(`选择${threshold}，购买商品小于${threshold}`)
        this.$message.error('所选商品PV小于申报PV')
        return false
      }
      return true
    },
    aWhetherexecute(){
      this.selectlevel = ''
      sessionStorage.removeItem('PromotionData')
      console.log(this.get1110time,this.get1109time,this.registerInfo.totalFc)
      if(this.get1110time && this.registerInfo.totalFc){
        let changeFc = 0
        if (this.registerInfo.totalFc.toString() == '100' && this.getTotalSum(3) >= 300 && this.getTotalSum(3) < 500) {
          changeFc = 300
        }
        if (this.registerInfo.totalFc.toString() == '100' && this.getTotalSum(3) >= 500 && this.getTotalSum(3) < 1500) {
          changeFc = 500
        }
        if (this.registerInfo.totalFc.toString() == '100' && this.getTotalSum(3) >= 1500) {
          changeFc = 1500
        }
        if (this.registerInfo.totalFc.toString() == '300' && this.getTotalSum(3) >= 500 && this.getTotalSum(3) < 1500) {
          changeFc = 500
        }
        if (this.registerInfo.totalFc.toString() == '300' && this.getTotalSum(3) >= 1500) {
          changeFc = 1500
        }
        if (this.registerInfo.totalFc.toString() == '500' && this.getTotalSum(3) >= 1500) {
          changeFc = 1500
        }
        let jibie= ''
        if(changeFc && changeFc != 0 ){
          jibie = changeFc.toString()
        }else{
          jibie = this.registerInfo.totalFc.toString()
        }
        switch (jibie) {
          case '100':
            this.selectlevel = '10'
            break;
          case '300':
            this.selectlevel = '20'
            break;
          case '500':
            this.selectlevel = '30'
            break;
          case '1500':
            this.selectlevel = '40'
            break;
          default: this.selectlevel = ''
        }
        console.log( this.selectlevel,'2')
      }else{
        this.showStep3()
      }
    },
    showStep3() {
      this.selectlevel = ''
      if (!this.checkTotalFcAndSum(this.registerInfo.totalFc, this.getTotalSum(3))) {
        return
      }
      let flag = ''
      let changeStr = ''
      let changeFc = 0
      if (this.registerInfo.totalFc == '100' && this.getTotalSum(3) >= 300 && this.getTotalSum(3) < 500) {
        console.log('选择100,购买商品大于等于300小于500')
        flag = '选择100,购买商品大于等于300小于500!'
        changeStr = '即将调整为300'
        changeFc = 300
      }
      if (this.registerInfo.totalFc == '100' && this.getTotalSum(3) >= 500 && this.getTotalSum(3) < 1500) {
        console.log('选择100,购买商品大于等于500小于1500')
        flag = '选择100,购买商品大于等于500小于1500!'
        changeStr = '即将调整为500'
        changeFc = 500
      }
      if (this.registerInfo.totalFc == '100' && this.getTotalSum(3) >= 1500) {
        console.log('选择100,购买商品大于等于1500')
        flag = '选择100,购买商品大于等于1500!'
        changeStr = '即将调整为1500'
        changeFc = 1500
      }
      if (this.registerInfo.totalFc == '300' && this.getTotalSum(3) >= 500 && this.getTotalSum(3) < 1500) {
        console.log('选择300,购买商品大于等于500小于1500')
        flag = '选择300,购买商品大于等于500小于1500!'
        changeStr = '即将调整为500'
        changeFc = 500
      }
      if (this.registerInfo.totalFc == '300' && this.getTotalSum(3) >= 1500) {
        console.log('选择300,购买商品大于等于1500')
        flag = '选择300,购买商品大于等于1500!'
        changeStr = '即将调整为1500'
        changeFc = 1500
      }
      if (this.registerInfo.totalFc == '500' && this.getTotalSum(3) >= 1500) {
        console.log('选择500,购买商品大于等于1500')
        flag = '选择500,购买商品大于等于1500!'
        changeStr = '即将调整为1500'
        changeFc = 1500
      }

      if(changeFc) {
        const h = this.$createElement
        this.$confirm(this.$t('common.tips'), {
          title: this.$t('common.tips'),
          message: h('div', [
            h('p', `${flag}`),
            h('p', `${changeStr}`),
          ]),
          confirmButtonText: this.$t('button.confirm'),
          cancelButtonText: this.$t('operation.button.cancel'),
          customClass: 'msgBox',
          type: 'warning',
        }).then(() => {
          this.registerInfo.totalFc = changeFc
          this.resGoodsList = []
          this.goodsList.map(item => {
            if (item.quantity > 0) {
              this.resGoodsList.push(item)
            }
          })
          this.tableData.map(ele => {
            this.resGoodsList.push(ele)
          })
          // this.registerInfo.totalAmount = ''
          if(sessionStorage.getItem('PromotionData')){
            let PromotionData = JSON.parse(sessionStorage.getItem('PromotionData'))
            PromotionData.forEach(v=>{
              this.resGoodsList.push(v)
            })
          }
          this.step = 3
        }).catch(() => {
        })
      } else {
        this.resGoodsList = []
        this.goodsList.map(item => {
          if (item.quantity > 0) {
            this.resGoodsList.push(item)
          }
        })
        this.tableData.map(ele => {
          this.resGoodsList.push(ele)
        })
        if(sessionStorage.getItem('PromotionData')){
          let PromotionData = JSON.parse(sessionStorage.getItem('PromotionData'))
          PromotionData.forEach(v=>{
            this.resGoodsList.push(v)
          })
        }
        // this.registerInfo.totalAmount = ''
        this.step = 3
      }
      this.setAmount(0, 1)
      console.log(JSON.parse(sessionStorage.getItem('PromotionData')),'PromotionDataPromotionData',this.resGoodsList)
    },
    getMemberno() {
      memberNoIsExit({'memberNo': this.registerInfo.memberNo}).then(res => {
        if (res.data !== null) {
          this.prohibitregistration = 'X'
          this.canregister = false
          this.$message.error(res.data)
        } else {
          this.prohibitregistration = '✓'
          this.canregister = true
        }
      }).catch(err => {
        console.error(err)
      })
    },
    setAgentAddr() {
      this.registerInfo.recAddr = this.AgentInfoObj ? this.AgentInfoObj.storeAddr : ''
      this.registerInfo.recName = this.AgentInfoObj ? this.AgentInfoObj.name : ''
      this.registerInfo.recPhone = this.AgentInfoObj ? this.AgentInfoObj.mobile : ''
      this.registerInfo.recNo = this.AgentInfoObj ? this.AgentInfoObj.agentNo : ''
    },
    setCompanyAddr() {
      this.registerInfo.outCompanycode = this.CompanyInfoObj ? this.CompanyInfoObj.companyCode : ''
      this.registerInfo.recAddr = this.CompanyInfoObj ? this.$t(this.CompanyInfoObj.Addr).toString() : ''
      this.registerInfo.recName = ''
      this.registerInfo.recPhone = this.CompanyInfoObj ? this.$t(this.CompanyInfoObj.Tel).toString() : ''
      this.registerInfo.recNo = ''
    },
    setMemberAddr() {
      this.registerInfo.recName = ''
      this.registerInfo.recPhone = ''
      this.registerInfo.recAddr = ''
      this.registerInfo.recNo = ''
    },
    outCompanyCodeRadioChange(val) {
      this.registerInfo.outCompanycode = val.companyCode
      this.CompanyInfoObj = val
      this.registerInfo.recAddr = this.$t(val.Addr).toString()
      this.registerInfo.recName = ''
      this.registerInfo.recPhone = this.$t(val.Tel).toString()
      this.registerInfo.recNo = ''
    },
    formCheck() {
      if (!this.registerInfo.recType) {
        this.$message.error(this.$t('info.select.shipment.type').toString())
        return false
      }
      if (this.registerInfo.recType == '3' && !this.cityCode) {
        this.$message.error((this.$t('poOrder.pleaseChoose')+this.$t('busi.address')).toString())
        return false
      }
      if (this.registerInfo.recType === '1') {
        if (!this.registerInfo.recNo) {
          this.$message.error(this.$t('Please.Selec.sa').toString())
          return false
        }
      } else if (this.registerInfo.recType === '2') {
        if (!this.registerInfo.outCompanycode) {
          this.$message.error(this.$t('Please.Selec.sb').toString())
          return false
        }
        // eslint-disable-next-line no-empty
      } else if (this.registerInfo.recType === '3') {

        if (!this.registerInfo.recAddr) {
          this.$message.error(this.$t('Please.Selec.da').toString())
          return false
        }
        if (!this.registerInfo.recName) {
          this.$message.error(this.$t('info.rec.name.required').toString())
          return false
        }
        if (!this.registerInfo.recPhone) {
          this.$message.error(this.$t('Please.Selec.pn').toString())
          return false
        }
      }

      return true
    },
    setRecName() {
      this.registerInfo.recName = this.registerInfo.name.toString()
    },
    setRecInfo(val) {
      this.registerInfo.recAddr = val.storeAddr
      this.registerInfo.recName = val.name
      this.registerInfo.recPhone = val.mobile
      this.registerInfo.recNo = val.agentNo
    },
    agentRadioChange(val) {
      this.registerInfo.recAddr = val.storeAddr
      this.registerInfo.recName = val.name
      this.registerInfo.recPhone = val.mobile
      this.registerInfo.recNo = val.agentNo
      this.AgentInfoObj = val
    },
    changeStatus(val) {
      this.registerInfo.totalFc = val
    },
    changRadio(val) {
      this.registerInfo.lr = val
    },
    showStep2() {
      if (!this.formCheck()) {
        return false
      }
      if (this.registerInfo.password !== this.registerInfo.tmpRePassword) {
        return this.$message.error(this.$t('error.password.not.accord').toString()) // 两次密码不一致
      }

      this.$refs['registerRef'].validate((valid) => {
        if (valid) {
          this.doLoading = true
          NewAgentRegisterFirst({...this.registerInfo, memberNo: this.memberNo})
              .then((res) => {
                this.resSaveInfo = res.data
                this.step = 2
                this.doLoading = false
              })
              .catch((err) => {
                console.error(err)
                this.doLoading = false
              })
        }
      })
    },
    Back(){
      this.step=2
      this.resGoodsList = []
      this.tableData = []
    },
    submitData(val) {
      if (this.registerInfo.totalAmount === 0 || this.registerInfo.totalAmount === '') {
        return this.$message.error(this.$t('register.us.select.number').toString()) // 请输入数字
      }
      this.$confirm(this.$t('fi.sure.handle').toString(), this.$t('common.tips').toString(), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('operation.button.cancel'),
        customClass: 'msgBox',
        type: 'warning'
      }).then(() => {
        this.resSaveInfo.poOrderVO.goodsList = this.resGoodsList
        this.resSaveInfo.poOrderVO.payPassword = this.registerInfo.payPassword
        this.resSaveInfo.poOrderVO.totalFc = this.registerInfo.totalFc
        this.resSaveInfo.poOrderVO.totalAmount = this.registerInfo.totalAmount
        this.resSaveInfo.poOrderVO.payType = val  // 0为立即支付 1为稍后支付
        this.resSaveInfo.poOrderVO.orderType = '17' // 17为首购单
        // 运费赋值
        this.resSaveInfo.poOrderVO.sendFree = this.getSendFree
        this.doLoading = true
        NewAgentRegisterSecond(this.resSaveInfo)
            .then((res) => {
              console.log(JSON.parse(JSON.stringify((res.data))), '注册结果')
              this.$router.push({path: '/order-list/index'})
              this.doLoading = false
            })
            .catch((err) => {
              console.error(err)
              this.doLoading = false
            })
      }).catch((err) => {
        console.error(err)
        this.doLoading = false
      })
    },
    tmpLinkNoBlur(val) {
      this.registerInfo.lr = ''
      if (val) {
        miMemberRightNum({memberNo: val})
            .then((res) => {
              if (res.data.leftMem == null || res.data.leftMem == '') {
                this.disabled1 = false
              }
              if (res.data.rightMem == null || res.data.rightMem == '') {
                this.disabled2 = false
              }
              this.tmpLinkNo_span = res.data.name
              this.tmpLinkNo_num_span = res.data.num
              this.tmpLinkNo_span_show = false
            })
            .catch(() => {
              this.tmpLinkNo_span = ''
              this.tmpLinkNo_num_span = ''
              this.tmpLinkNo_span_show = true
            })
      } else {
        this.tmpLinkNo_span = ''
        this.tmpLinkNo_num_span = ''
        this.tmpLinkNo_span_show = false
      }
    },
    // 获取账户余额
    getBalance () {
      getPersonalData2().then(res => {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].acType === 'B$') {
            this.B$ = res.data[i].validBalance
          }
        }
        this.doLoading = false
      }).catch(err => {
        this.doLoading = false
        console.error(err)
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.radioJiaZeng {
  display: block;
  margin: 10px 0;
}
.hong-xing:before {
  content: "*";
  color: #F56C6C;
  margin-right: 4px;
}

.el-input-center {
  min-width: 100px;

  ::v-deep input {
    text-align: center !important;
  }
}

.account_name_css:after {
  content: 'X';
  color: #ff4949;
}

.red-span {
  color: red;
}

.register_3_css {
  display: flex;

  ::v-deep .el-form-item {
    margin-bottom: 0
  }
}

.AgentRadio ::v-deep .el-radio__input {
  height: 35px;
}

.under-line {
  border-bottom: 1px solid #ddd
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}
</style>
