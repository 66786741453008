
<template>
<!--  促销时间：2024.11.9
促销内容：会议当天金+和钻卡加入人员，免费获得老款魔力仪1台。升级到金+人员加100美元获得老款魔力仪1台。升级到钻卡人员免费获得老款魔力仪1台。
操作方式：升级到金+弹对话框，询问是否加赠-->
<div>
  <p style="color: red;font-size: 16px">Aumenta a oro plus y paga 100 dólares para obtener una máquina mágica del modelo anterior.</p>
  <div style="text-align: left">
    <el-radio v-model="radio" @input="getMLY_method" label="-119">+100$ M024-00</el-radio>
  </div>
</div>
</template>

<script>

export default {
  data(){
    return{
      radio:''
    }
  },
  props:{
    dialogVisible:{
      type:Boolean
    },
  },
  watch:{
    'dialogVisible':function (val){
      if(val){
        this.radio =''
      }
    },
  },
  methods:{
    getMLY_method(val){
      console.log(val)
      this.$emit('getMLY_method',val)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
