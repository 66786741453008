<template>
<div>
  <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="dialogVisible"
      width="30%">
    <div>
<!--      2024-11-9 -->
      <CX2024119 :dialogVisible="dialogVisible"  v-if="selectlevel==='30' && orderType=== 18 && get1109time" @getMLY_method="getMLY_method"></CX2024119>
      <CX2024110 :dialogVisible="dialogVisible" :orderType="orderType" :selectlevel="selectlevel" v-if="(selectlevel==='10' ||selectlevel==='20' ||selectlevel==='30' ||selectlevel==='40') && ( orderType=== 18 || orderType ===17) && get1110time" @get_BlackFive_method="get_BlackFive_method"></CX2024110>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="PromotionCancel">{{$t('operation.button.cancel')}}</el-button>
    <el-button type="primary" @click="PromotionConfirmed()">{{$t('button.confirm')}}</el-button>
  </span>
  </el-dialog>

</div>
</template>
<script>
import CX2024119 from '@/components/Promotions/2024_11_9.vue'
import CX2024110 from '@/components/Promotions/2024_1110_1117.vue'
import {RUSAugustPromotion1109, RUSAugustPromotion1110} from "@/api/promotion/time";
export default {
  components:{CX2024119,CX2024110},
  data(){
    return{
      PromotionData:[],
      dialogVisible:false,
      // 2024-11-09
      get_2024_11_9_id:'',
      get1109time:true,
      //2024-11-10 - 2024-11-17
      get_2024_11_10_id:'',
      get1110time:true
    }
  },
  props:{
    orderType:{
      type:Number
    },
    selectlevel:{
      type:String
    },
    levelType:{
      type:String
    },
  },
  watch:{
    'orderType':function (val){
      sessionStorage.removeItem('PromotionData')
      if(val === 18 && this.selectlevel=== '30' && this.get1109time){
        this.dialogVisible = true
      }
      if(val && (val !== 19 ) && (this.selectlevel !== '') && this.get1110time){
        this.dialogVisible = true
      }
    },
    'selectlevel':function (val){
      if(val === '30' && this.orderType === 18 && this.get1109time){
        this.dialogVisible = true
      }
      if(this.orderType && this.orderType !== 19 && val !== '' && this.get1110time){
        this.dialogVisible = true
      }
    },
  },
  created() {
    sessionStorage.removeItem('PromotionData')
    this.getTime()
  },
  computed:{
  },
  methods:{
    getTime(){
      RUSAugustPromotion1109().then(res=>{
        this.get1109time = res.data
        // this.get1109time = false
      })
      RUSAugustPromotion1110().then(res=>{
        this.get1110time = res.data
        // this.get1110time = false

      })
    },
    getMLY_method(val){
      this.get_2024_11_9_id = val
    },
    get_BlackFive_method(val){
      this.get_2024_11_10_id = val
    },
    PromotionConfirmed(){
      sessionStorage.removeItem('PromotionData')
      if(this.get_2024_11_9_id){
        let obj = {
          goodsName:'Dispositivo Portátil de Masaje PLATA CAJA',
          goodsCode:'M024-00',
          quantity:1,
          id:this.get_2024_11_9_id,
          PV:0,
          standardPv:0,
          standardPriceB:1800,
          proF000:0,
        }
        this.PromotionData.push(obj)
      }
      if(this.get_2024_11_10_id){
        let obj1 = {
          goodsName:'',
          goodsCode:'',
          quantity:1,
          id:this.get_2024_11_10_id,
          PV:0,
          standardPv:0,
          standardPriceB:0,
          proF000:0,
        }
        switch (this.get_2024_11_10_id){
          case '-120':
            obj1.goodsName = 'Regalo de paquete de 30 dólares'
            obj1.standardPriceB = 540
            obj1.standardPrice = 30
                break;
          case '-121':
            obj1.goodsName = 'Regalo de paquete de 60 dólares'
            obj1.standardPriceB = 1080
            obj1.standardPrice = 60
            break;
          case '-122':
            obj1.goodsName = 'Regalo de paquete de 100 dólares'
            obj1.standardPriceB = 1800
            obj1.standardPrice = 100
            break;
          case '-123':
            obj1.goodsName = 'Regalo de paquete de 150 dólares'
            obj1.standardPriceB = 2700
            obj1.standardPrice = 150
            break;
          default:
            obj1.goodsName = ''
            obj1.standardPriceB = 0
            obj1.standardPrice = 0
        }
        this.PromotionData.push(obj1)
      }
      if(this.PromotionData){
        sessionStorage.setItem('PromotionData',JSON.stringify(this.PromotionData))
      }
      this.$emit('confirm',  true)
      this.dialogVisible = false
      this.PromotionData = []
      this.get_2024_11_9_id = ''
      this.get_2024_11_10_id = ''
    },
    PromotionCancel(){
      sessionStorage.removeItem('PromotionData')
      this.$emit('confirm',  true)
      this.dialogVisible = false
      this.PromotionData = []
      this.get_2024_11_9_id = ''
      this.get_2024_11_10_id = ''
    },
  }
}
</script>

<style scoped lang="scss">

</style>
