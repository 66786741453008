
<template>
<!--  促销时间：2024.11.10-2024.11.17
促销期间银级加入人员	30	防蓝光眼镜（黑）1付 六味茶1盒 纳豆胶囊1盒
促销期间金级加入人员或升级至金级人员	60	枸杞植物饮料1盒 防蓝光眼镜（黑）1付 蛹虫草胶原蛋白肽速溶咖啡1盒 六味茶1盒 灵芝胶囊1盒 纳豆胶囊1盒
促销期间金+级加入人员或升级至金+级人员	100	卫生巾(19包)礼盒装1盒 枸杞植物饮料2盒 草本金胶1盒 防蓝光眼镜（黑）1付 抗皱紧致喷雾1盒 蛹虫草胶原蛋白肽速溶咖啡1盒六味茶2盒 灵芝胶囊2盒 纳豆胶囊1盒
促销期间钻卡加入人员或升级至钻卡人员	150	卫生巾(19包)礼盒装1盒 绿咖啡2盒 枸杞植物饮料4盒 草本金胶2盒 防蓝光眼镜（黑）1付 标准版足贴2盒 抗皱紧致喷雾1盒 蛹虫草胶原蛋白肽速溶咖啡1盒 六味茶2盒 钙铁锌硒胶囊2盒 灵芝胶囊2盒 纳豆胶囊1盒
-->
  <div>
    <p style="color: red;font-size: 16px">Promoción</p>
    <div style="text-align: left">
      <el-radio v-if="(orderType!== 19) && (Number(selectlevel) >=10)" v-model="radio" @input="get_BlackFive_method" label="-120">Regalo de paquete de 30 dólares </el-radio>
      <br>
      <el-radio v-if="(orderType!== 19) && (Number(selectlevel) >=20)" v-model="radio" @input="get_BlackFive_method" label="-121">Regalo de paquete de 60 dólares</el-radio>
      <br>
      <el-radio v-if="(orderType!== 19) && (Number(selectlevel) >=30)" v-model="radio" @input="get_BlackFive_method" label="-122">Regalo de paquete de 100 dólares</el-radio>
      <br>
      <el-radio v-if="(orderType!== 19) && (Number(selectlevel) >=40)" v-model="radio" @input="get_BlackFive_method" label="-123">Regalo de paquete de 150 dólares</el-radio>
    </div>
  </div>
</template>

<script>

export default {
  data(){
    return{
      radio:''
    }
  },
  props:{
    orderType:{
      type:Number
    },
    selectlevel:{
      type:String
    },
    dialogVisible:{
      type:Boolean
    },
  },
  watch:{
    'dialogVisible':function (val){
      if(val){
        this.radio =''
      }
    },
  },
  created() {
  },
  methods:{
    get_BlackFive_method(val){
      this.$emit('get_BlackFive_method',val)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
